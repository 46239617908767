$mobile-header-padding: 24px;

.MOBILE {
  &.area {
    padding: 0 $mobile-header-padding;

    .logo {
      left: $mobile-header-padding;
    }

    .drawerWrap {
      padding: 32px;
    }

    .language-menu {
      position: absolute;
      right: calc($mobile-header-padding + 40px);

      .language {
        @include font('b2', false);
        line-height: 16px;
        color : $color-gray-400;
        cursor: pointer;

        &.active {
          font-weight: 700;
          color : $color-gray-800;
          &.DARK {
            color : $color-white;
          }
        }

        &:first-child {
          padding-right: 10px;
        }

        &:not(:first-child) {
          border-left : 1px solid $color-gray-300;
          padding-left: 10px;
        }
      }
    }

    .menuBtn {
      right: $mobile-header-padding;
    }
  }
}
