@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.wrapper {
  position: relative;
  user-select: none;
  display: flex;
}

.options {
  z-index: 700;
  position: fixed;
  width: max-content;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.05);
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}