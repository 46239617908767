@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.paragraph {
  font-weight: 500;

  &.variant-B1 {
    font-size: 18px;
    line-height: 26px;
  }

  &.variant-B2 {
    font-size: 16px;
    line-height: 24px;
  }

  &.variant-B3 {
    font-size: 14px;
    line-height: 20px;
  }

  &.variant-B4 {
    font-size: 12px;
    line-height: 18px;
  }

  &.bold {
    font-weight: 700;
  }
}