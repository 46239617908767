.preview-main {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: $color-white;
    width: 4px;
    position: absolute;
    right: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-400;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-white;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    width: 0;
  }
}

.preview-buttons {
  display: flex;
  @include font('b3', true);
  border-top: 1px solid $color-gray-400;

  .preview-button {
    width: 100%;
    padding: 15px 0;
    background-color: $color-gray-80;
    text-align: center;
  }
  .div-hori {
    flex-shrink: 0;
    width: 1px;
    background-color: $color-gray-400;
  }
}
