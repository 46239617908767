@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';
// local color variables
$teal-main: $color-teal-600;
$teal-sub: $color-teal-50;
$green-main: $color-green-600;
$green-sub: $color-green-100;
$red-main: $color-red-600;
$red-sub: $color-red-50;
$blue-main: $color-cyan-600;
$blue-sub: $color-cyan-50;

.tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 4px;

  // ------------------- GROUP -------------------------
  &.primary {
    color: $color-white;

    &.teal {
      background-color: $teal-main;
    }

    &.green {
      background-color: $green-main;
    }

    &.red {
      background-color: $red-main;
    }

    &.gray {
      background-color: $color-gray-500;
    }

    &.blue {
      background-color: $blue-main;
    }

    &.dark {
      background-color: $color-gray-700;
    }
  }

  &.secondary {
    &.teal {
      color: $teal-main;
      background-color: $teal-sub;
    }

    &.green {
      color: $green-main;
      background-color: $green-sub;
    }

    &.red {
      color: $red-main;
      background-color: $red-sub;
    }

    &.gray {
      color: $color-gray-600;
      background-color: $color-gray-200;
    }

    &.blue {
      color: $blue-main;
      background-color: $blue-sub;
    }

    &.dark {
      color: $color-gray-700;
      background-color: $color-gray-80;
    }
  }

  &.outlined {
    background-color: $color-white;

    &.teal {
      color: $teal-main;
      border: 1px solid $teal-main;
    }

    &.green {
      color: $green-main;
      border: 1px solid $green-main;
    }

    &.red {
      color: $red-main;
      border: 1px solid $red-main;
    }

    &.gray {
      color: $color-gray-500;
      border: 1px solid $color-gray-500;
    }

    &.blue {
      color: $blue-main;
      border: 1px solid $blue-main;
    }

    &.dark {
      color: $color-gray-700;
      border: 1px solid $color-gray-700;
    }
  }

  &.round {
    border-radius: 10000px;
  }

  // -------------------- SIZE --------------------
  &.lg {
    height: 26px;
    padding: 4px 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }

  &.sm {
    height: 22px;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &.bold {
    font-weight: 700;
  }
}
