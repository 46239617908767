@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/base.scss';
@import 'ui/component/popup/Popup.module.scss';

$margin: 48px;

.preview-popup-container {
  position: fixed;
  z-index: 100;

  &.row-A {
    top: $margin;
  }
  &.row-B {
    top: calc(50vh - $margin);
  }
  &.row-C {
    bottom: $margin;
  }
  &.col-A {
    left: $margin;
  }
  &.col-B {
    left: calc(50vw - $margin);
  }
  &.col-C {
    right: $margin;
  }

  .preview-popup {
    position: absolute;
    border-radius: 8px;
    border: 1px solid $color-gray-400;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    &.row-B.col-B {
      transform: translate(-50%, -50%);
    }
    &.row-B {
      transform: translate(0, -50%);
    }
    &.col-B {
      transform: translate(-50%, 0);
    }
  }
}

.preview-popup-mobile {
  position: absolute;
  z-index: 100;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  border: 1px solid $color-gray-400;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;

  .preview-main {
    width: 320px;
    max-height: 360px;
  }
}

.button {
  cursor: pointer;
}
