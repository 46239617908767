@mixin margins($text-margin, $icon-margin, $icon-text-big-margin, $icon-text-small-margin) {
  > span {
    &:first-child {
      margin-left: $text-margin;
    }

    &:last-child {
      margin-right: $text-margin;
    }

    & + svg {
      margin-left: $icon-text-big-margin;
    }
  }

  > svg {
    &:first-child {
      margin-left: $icon-margin;

      & + span {
        margin-left: $icon-text-big-margin;
      }
    }

    &:last-child {
      margin-right: $icon-margin;
    }

    & + span {
      margin-left: $icon-text-small-margin;

      & + svg {
        margin-left: $icon-text-big-margin;
      }
    }
  }
}

@mixin colors($default-bg, $default-text, $hover-bg, $hover-text, $disabled-bg, $disabled-text, $focus-outline) {
  color: $default-text;
  background: $default-bg;

  > svg {
    fill: $default-text;
  }

  &:not(:disabled) {
    &:hover {
      color: $hover-text;
      background: $hover-bg;

      > svg {
        fill: $hover-text;
      }
    }

    &:focus {
      outline: 3px solid $focus-outline;
    }
  }

  &:disabled {
    color: $disabled-text;
    background: $disabled-bg;

    > svg {
      fill: $disabled-text;
    }
  }
}

@mixin colors-bg($default, $hover, $disabled) {
  background: $default;

  &:not(:disabled) {
    &:hover {
      background: $hover;
    }
  }

  &:disabled {
    background: $disabled;
  }


}

@mixin colors-text($default, $hover, $disabled) {
  color: $default;

  > svg {
    fill: $default;
  }

  &:not(:disabled) {
    &:hover {
      color: $hover;

      > svg {
        fill: $hover;
      }
    }
  }

  &:disabled {
    color: $disabled;

    > svg {
      fill: $disabled;
    }
  }
}

@mixin colors-border($default, $hover, $disabled, $active) {
  border-color: $default;

  &:not(:disabled) {
    &:hover {
      border-color: $hover;
    }
  }

  &:disabled {
    border-color: $disabled;

    &:active {
      outline: none;
      border-color: $disabled;
    }
  }

  &:active {
    border-color: transparent;
    outline: 3px solid $active;
  }
}
