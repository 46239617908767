@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.input {
  &:checked + .radio {
    box-shadow: none;
    border: 5px solid $color-teal-600;

    &:hover {
      border-color: $color-teal-700;
    }

    &:focus-visible {
      outline-color: $color-teal-100;
    }
  }

  &:disabled {
    & + .radio {
      cursor: not-allowed;
      background: $color-gray-100;
      box-shadow: none;
      border: none;
    }

    &:checked + .radio {
      border: 5px solid $color-gray-100;
      background: $color-gray-400;
    }
  }
}

.radio {
  border: 2px solid $color-gray-400;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid $color-gray-200;
  }
}
