@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/mixins';

.modal-body {
  width: 880px;
  height: 480px;
  overflow-y: scroll;
  padding: 24px 9px 24px 24px;

  > p {
    @include font('b3');
    margin-bottom: 16px;
    color: $color-gray-600;
  }

  .preview-image-wrapper {
    border: 1px solid $color-gray-400;
    border-radius: 6px;
    overflow: hidden;
  }
}
