@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;

  .left {
    display: flex;
    align-items: center;
    width: 130px;
    color: $color-gray-700;
    font-size: 14px;
    user-select: none;

    > svg {
      margin-right: 10px;
    }
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;

    &:only-child {
      width: 100%;
    }

    .pagination {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      > li {
        width: 32px;
        height: 32px;
        border-radius: 10px;
        margin-right: 5px;
        color: $color-gray-500;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        &.active,
        &:hover {
          color: $color-gray-800;
          background: $color-gray-100;
          border-radius: 16px;
        }
      }
    }

    .btn {
      border-radius: 16px;

      &.active,
      &:hover {
        border-radius: 16px;
        cursor: pointer;

        &.possible > svg {
          fill: #121619;
        }
      }

      &:disabled {
        > svg {
          fill: $color-gray-500;
        }

        &.active,
        &:hover {
          cursor: not-allowed;
          background: none;
        }
      }

      &.prev {
        margin-right: 10px;

        &:focus {
          border: 1px solid $color-gray-100;
          box-shadow: 0 0 0 1px $color-gray-100;
          background: none;
        }
      }

      &.next {
        margin-left: 10px;

        &:focus {
          border: 1px solid $color-gray-100;
          box-shadow: 0 0 0 1px $color-gray-100;
          background: none;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    width: 130px;
    font-size: 14px;
    font-weight: 500;
    user-select: none;

    .margin {
      margin-right: 10px;
    }

    .select {
      width: 68px;
      font-weight: 400;
    }
  }
}

.hidden {
  visibility: hidden;
}
