@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';
@import 'ui/styles/fonts';

.wrapper {
  position: relative;
  width: 100%;
  height: 40px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 10px;

  @include font(b3, $no-line-height: true);

  cursor: pointer;

  &.lg {
    height: 48px;
    padding: 0 16px;
  }

  &.sm {
    height: 32px;
  }

  > .selected-name {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    overflow: hidden;

    > .left-icon {
      flex-shrink: 0;
    }

    > .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  > .arrow-icon {
    flex-shrink: 0;
  }

  &:not(.dark) {
    background: $color-gray-80;

    > .selected-name {
      color: $color-gray-800;

      &.placeholder {
        color: $color-gray-600;

        > svg {
          fill: $color-gray-600;
        }
      }

      > svg {
        fill: $color-gray-800;
      }
    }

    > .arrow-icon {
      fill: $color-gray-600;
    }

    &:hover {
      background: $color-gray-100;
    }

    &.open {
      @extend %focus-box;
    }
  }

  &.dark {
    background: $color-gray-800;

    > .selected-name {
      color: white;

      > svg {
        fill: white;
      }
    }

    > .arrow-icon {
      fill: white;
    }

    &:hover {
      background: black;
    }

    &.open {
      outline: 2px solid $color-gray-400;
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: $color-gray-100;

    > .selected-name {
      color: $color-gray-400 !important;

      > svg {
        fill: $color-gray-400;
      }
    }

    > .arrow-icon {
      fill: $color-gray-400;
    }
  }

  &.error {
    box-shadow: 0 0 0 2px inset $color-red-600;
  }
}

%focus-box {
  background: white;
  box-shadow: inset 0 0 0 2px $color-gray-800;
  outline: none;

  > .selected-name {
    color: $color-gray-800;

    > svg {
      fill: $color-gray-800;
    }
  }

  > .arrow-icon {
    fill: $color-gray-700;
  }
}
