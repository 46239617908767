@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.interactiveArea {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  outline: none;
  touch-action: none;

  &:focus .pointer {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
