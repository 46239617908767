$tablet-header-padding: 40px;

.TABLET {
  &.area {
    padding: 0 $tablet-header-padding;

    .logo {
      left: $tablet-header-padding;
    }

    .drawerWrap {
      padding: 32px 48px;
    }

    .language-menu {
      position: absolute;
      right: calc($tablet-header-padding + 48px);

      .language {
        @include font('b2', false);
        line-height: 16px;
        color : $color-gray-400;
        cursor: pointer;

        &.active {
          font-weight: 700;
          color : $color-gray-800;

          &.DARK {
            color : $color-white;
          }
        }

        &:first-child {
          padding-right: 10px;
        }

        &:not(:first-child) {
          border-left : 1px solid $color-gray-300;
          padding-left: 10px;
        }
      }
    }

    .menuBtn {
      right: $tablet-header-padding;
    }
  }
}
