@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.icon-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition: all 0.4s;

  > svg {
    transition: all 0.4s;
  }

  /**********************************************
                  타입
  **********************************************/
  &.variant-default {
    padding: 3px;
    border-radius: 4px;

    &:hover {
      background-color: #e7e8ea;

      > svg {
        fill: #656a71;
      }
    }

    &:active {
      background-color: transparent;
    }

    &:disabled {
      cursor: not-allowed;

      > svg {
        fill: #e7e8ea;
      }
    }
  }

  &.variant-primary {
    padding: 3px;
    border-radius: 4px;

    > svg {
      fill: #656a71;
    }

    &:hover {
      background-color: #e7e8ea;

      > svg {
        fill: #656a71;
      }
    }

    &:active {
      background-color: $color-teal-50;

      > svg {
        fill: $color-green;
      }
    }

    &:focus {
      border: 1px solid $color-green;
      box-shadow: 0 0 0 1px $color-green;
      background-color: #e0f9e4;

      > svg {
        fill: #656a71;
      }
    }
  }

  &.variant-circle {
    padding: 8px;
    border: 1px solid $color-gray-400;
    border-radius: 100%;

    > svg {
      fill: $color-gray-800;
    }

    &:hover {
      background-color: $color-gray-100;
      border: 1px solid $color-gray-400;
    }

    &:focus {
      background-color: $color-gray-100;
      border: 1px solid $color-gray-400;
      box-shadow: 0 0 0 1px $color-gray-400;
    }

    &:disabled {
      border: 1px solid $color-gray-400;
      svg {
        fill: $color-gray-400;
      }
    }
  }

  &.variant-circle-line {
    padding: 8px;
    border: 1px solid #eaebee;
    border-radius: 100%;

    > svg {
      fill: black;
    }

    &:hover {
      background: #e7e8ea;
      border: 1px solid #eaebee;
    }

    &:active {
      border: 1px solid #eaebee;
      background: $color-teal-100;
    }

    &:focus {
      border: 1px solid $color-green;
      box-shadow: 0 0 0 1px $color-green;
    }
  }

  &.variant-card {
    padding: 0;
    border-radius: 4px;
    border: none;

    svg {
      fill: $color-gray-500;
    }

    &:hover {
      svg {
        fill: $color-gray-800;
      }
    }

    &:active {
      svg {
        fill: $color-gray-600;
      }
    }

    &:focus {
      background-color: rgba(white, 0.4);
      svg {
        fill: $color-gray-800;
      }
    }

    &:disabled {
      background: none;
      cursor: not-allowed;

      svg {
        fill: $color-gray-400;
      }
    }
  }

  &:disabled {
    background: none;
    border-color: transparent;
    cursor: not-allowed;

    svg {
      fill: $color-gray-400 !important;
    }
  }
}
