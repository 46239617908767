@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.swipe-arrow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.swipe-arrow {
  &.sm {
    width: 24px;
    height: 24px;
  }
  &.md {
    width: 32px;
    height: 32px;
  }
  &.lg {
    width: 40px;
    height: 40px;
  }
}

/** variant : ghost */
.swipe-arrow.ghost {
  background-color: transparentize($color-gray-300, 1);

  & > .indicator__svg {
    fill: $color-gray-600;
  }

  &:hover {
    background-color: $color-gray-300;
    & > .indicator__svg {
      fill: $color-gray-800;
    }
  }

  &.isFocused {
    border: 1px solid $color-gray-400;
    & > .indicator__svg {
      fill: $color-gray-800;
    }

    &:hover {
      background-color: transparentize($color-gray-300, 1);
    }
  }

  &:disabled {
    border: 0;
    background-color: transparentize($color-gray-300, 1);
    & > .indicator__svg {
      fill: $color-gray-400;
    }
  }
}

/** variant : fill */
.swipe-arrow.fill {
  background-color: $color-gray-600;

  & > .indicator__svg {
    fill: $color-white;
  }

  &:hover {
    background-color: $color-gray-800;
  }

  &.isFocused {
    background-color: $color-gray-800;
  }

  &:disabled {
    background-color: $color-gray-400;
  }
}

/** variant : outlined */
.swipe-arrow.outlined {
  border: 1px solid $color-gray-400;

  & > .indicator__svg {
    fill: $color-gray-800;
  }

  &:hover {
    border: 1px solid $color-gray-800;
    & > .indicator__svg {
      fill: $color-gray-800;
    }
  }

  &.isFocused {
    border: 1px solid $color-gray-800;
    background-color: transparentize($color-gray-300, 0.5);
    & > .indicator__svg {
      fill: $color-gray-800;
    }
  }

  &:disabled {
    border: 1px solid $color-gray-400;
    background-color: transparentize($color-gray-300, 1);
    & > .indicator__svg {
      fill: $color-gray-400;
    }
  }
}

/** variant : blur */
.swipe-arrow.blur {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  background-color: transparentize($color-white, 0.1);
  backdrop-filter: blur(4px);

  & > .indicator__svg {
    fill: $color-gray-600;
  }

  &:hover {
    background-color: transparentize($color-white, 0.2);
    border: 1px solid $color-gray-800;
    & > .indicator__svg {
      fill: $color-gray-800;
    }
  }

  &.isFocused {
    background-color: transparentize($color-white, 0.2);
    border: 1px solid $color-gray-800;
    & > .indicator__svg {
      fill: $color-gray-800;
    }
  }

  &:disabled {
    border: 0;
    & > .indicator__svg {
      fill: $color-gray-400;
    }
  }
}
