@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/mixins';

.MOBILE {
  &.container {
    justify-content: left;
    margin-top: 24px;

    .content {
      flex-wrap: wrap;
    }
  }
}

.container {
  display: flex;
  justify-content: right;
  align-items: flex-start;
  max-width: 635px + 184px;

  .content {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    gap: 8px;

    .icon {
      @include icon-outlined-button;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;

      > svg {
        fill: $color-gray-600;
      }
    }

    .add-sns-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 2px solid $color-gray-400;
      border-radius: 4px;
      width: 32px;
      height: 32px;

      &.darkMode {
        border: 2px solid $color-gray-600;
      }
    }
  }
}
