@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.area {
  position: relative;
  display: flex;
  width: 652px;
  height: 324px;
  padding: 40px;
  border-radius: 12px;
  user-select: none;
  cursor: default;
  background: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  z-index: 300;

  &.isContent {
    box-shadow: none;
  }
}

.controlArea {
  width: 296px;
  margin-left: 32px;
  padding-top: 16px;

  .brand-color-wrapper {
    margin: 14px 0;
    display: flex;
    align-items: center;

    // 체크박스형 브랜드 컬러
    label.check-label {
      margin-left: 8px;
      @include font('b3');
    }

    // 버튼형 브랜드 컬러
    button.brand-color {
      background: none;
      display: flex;
      gap: 4px;
      align-items: center;

      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: $color-green-600;
    }
  }

  .colorInputWrap {
    margin-top: 24px;

    &.brand-color {
      margin-top: 8px;
    }
  }
}
