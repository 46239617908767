@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.background-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  display: flex;
  justify-content: center;

  .background {
    width: 100%;
  }
}
