@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.input {
  width: 0;
  height: 0;
  margin: 0;

  &:checked:not(:disabled) {
    & + .checkbox {
      background: $color-teal-600;
      box-shadow: none;
    }

    &:hover + .checkbox {
      background: $color-teal-700;
      box-shadow: inset 0 0 0 2px $color-teal-700;
    }
  }

  &:not(:disabled) {
    &:hover {
      & + .checkbox {
        box-shadow: inset 0 0 0 2px $color-gray-500;

        & + label {
          cursor: pointer;
        }
      }
    }

    &:focus-visible + .checkbox {
      box-shadow: inset 0 0 0 2px $color-teal-700;
    }
  }

  &:disabled + .checkbox {
    cursor: not-allowed;
    background: $color-gray-100;
    box-shadow: none;

    > svg {
      fill: $color-gray-400;
    }

    & + label {
      cursor: not-allowed;
    }
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 3.33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 2px $color-gray-400;
  cursor: pointer;
  background: white;

  &.circle {
    border-radius: 10px;
  }

  > svg {
    fill: white;
  }

  &.indeterminate {
    background: $color-teal-600;
    box-shadow: inset 0 0 0 2px $color-teal-600;
  }
}
