@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import '../../../../../../node_modules/ui/styles/colors';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  margin-right: 8px;
  cursor: pointer;

  &.active {
    box-shadow: 0 0 0 2px $color-gray-500;
    background: $color-gray-100;
    border-radius: 4px;
  }

  &:last-child {
    margin-right: 0;
  }

  &.select-font {
    justify-content: space-between;
    width: 150px;
  }

  &.select-fontSize {
    justify-content: space-between;
    width: 56px;
  }

  &.select-fontType {
    justify-content: space-between;
    width: 96px;
  }

  .color-picker-modal {
    position: absolute;
    top: 40px;
    left: -550px;
  }

  > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .icon {
    flex-shrink: 0;
  }
}

.popover-wrapper {
  position: absolute;
  top: 30px;
  left: 0;
  overflow: hidden;
  padding: 4px;
  margin: 0;
  background: white;
  border: 1px solid $color-gray-200;
  box-sizing: border-box;
  box-shadow: 0 10px 15px rgb(0 0 0 / 10%), 0 4px 6px rgb(0 0 0 / 5%);
  border-radius: 6px;
  z-index: 500;

  &.font-family {
    left: -11px;
    width: 175px;

    > li {
      display: flex;
    }
  }

  &.font-size {
    left: -10px;
    width: 80px;
  }

  &.font-type {
    left: -16px;
    width: 124px;
  }

  .popover-container {
    list-style: none;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray-600;
    margin: 0;
    padding: 0;
    max-height: calc(32px * 6);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      position: absolute;
      width: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-gray-500, 0.8);
      border-radius: 16px 8px 8px 16px;
      background-clip: padding-box;
      border-left: 4px solid transparent;
    }

    > li {
      display: flex;
      align-items: center;
      padding: 6px 10px;
      height: 32px;
      min-width: 0;

      > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:hover,
      &.active {
        color: $color-gray-800;
      }

      &.active {
        font-weight: 700;
      }

      &:hover {
        background: $color-gray-80;
        border-radius: 4px;
      }
    }
  }
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: transparent;
  border-radius: 4px;
  margin-right: 4px;

  &.active {
    box-shadow: 0 0 0 2px $color-gray-500;
    background: $color-gray-100;
  }

  .hyper-wrapper {
    position: absolute;
    top: 30px;
    right: -17px;
    padding: 10px 16px !important;
    width: 336px;
    border: 1px solid $color-gray-400;
    box-shadow: 0px 2px 6px 0px #0000004d;
    box-shadow: 0px 0px 20px 0px #0000001a;
    border-radius: 8px;
    z-index: 160;
    background: white;

    .tab {
      width: 100%;
      margin-bottom: 14px;
    }

    .bottom {
      display: flex;

      .dropdown-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .save {
          min-width: 58px;

          > span {
            white-space: nowrap;
          }
        }
      }

      .url-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        .input {
          width: 100%;
        }

        .url-button {
          min-width: 58px;
          margin-left: 8px;
        }
      }
    }

    .reset-link-wrapper {
      margin-top: 16px;
      text-align: end;

      span {
        cursor: pointer;
        display: inline-flex;
        gap: 8px;
        align-items: center;
        @include font('b4');
        color: $color-gray-800;
      }
    }
  }
}

.image-file-upload {
  width: 0;
  height: 0;
  visibility: hidden;
}
