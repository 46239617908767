@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.alphaArea {
  position: relative;
  height: 20px;
  margin-top: 8px;
  border-radius: 100px;
  background-color: $color-white;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill-opacity=".05"><rect x="4" width="4" height="4"/><rect y="4" width="4" height="4"/></svg>');
  cursor: pointer;

  .alphaGradient {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  }
}
