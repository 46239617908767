@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
// 페이지 top 버튼
.btnTop {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: $color-gray-900;
  z-index: 30;

  &.visible {
    display: flex;
  }

  &.mobile {
    width: 44px;
    height: 44px;
    right: 14px;
    bottom: 14px;

    &.need-move {
      bottom: 70px;
    }
  }

  &:not(.mobile) {
    width: 56px;
    height: 56px;
    right: 24px;
    bottom: 24px;

    &.need-move {
      bottom: 88px;
    }
  }
}
