@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ats-editor/src/component/editor/setting/common/minxin';

.container {
  position: relative;
  display: flex;

  .bannerWrap {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transform: translate(0, 10px);
    z-index: 1;

    &.isCapture {
      opacity: 1;
      transform: translate(0, 0);
    }

    &.fadeIn {
      animation: fadeIn 0.4s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
