@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.wrapper {
  display: flex;
  overflow: hidden;

  .banner {
    width: 100%;
    transition: 0.4s transform;

    &.cursor {
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }

    &.RATIO {
      max-width: 1760px;
    }
  }
}
