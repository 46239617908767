@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'mixins';
@import 'ui/styles/colors';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  cursor: pointer;
  border: 1px solid transparent;

  > span {
    white-space: nowrap;
  }

  > svg {
    flex-shrink: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  // ------------------- GROUP -------------------------
  &.primary {
    @include colors-bg($color-gray-800, black, $color-gray-100);
    @include colors-text(white, white, $color-gray-400);
    @include colors-border(none, none, none, $color-gray-400);

    &.teal {
      @include colors-bg($color-teal-600, $color-teal-700, $color-gray-100);
      @include colors-text(white, white, $color-gray-400);
      @include colors-border(none, none, none, $color-teal-200);
    }
  }

  &.secondary {
    @include colors-bg($color-gray-80, $color-gray-100, $color-gray-100);
    @include colors-text($color-gray-700, $color-gray-700, $color-gray-400);
    @include colors-border(none, none, none, $color-gray-300);

    &.teal {
      @include colors-bg($color-teal-50, $color-teal-700, $color-gray-100);
      @include colors-text($color-teal-600, white, $color-gray-400);
      @include colors-border(none, none, none, $color-teal-300);
    }

    &.error {
      @include colors-bg($color-red-50, $color-red-500, $color-gray-100);
      @include colors-text($color-red-600, white, $color-gray-400);
      @include colors-border(none, none, none, $color-red-200);
    }

    &.dark {
      @include colors-bg($color-gray-700, $color-gray-900, $color-gray-900);
      @include colors-text(white, white, $color-gray-700);
      @include colors-border(none, none, none, $color-gray-600);
    }
  }

  &.outlined {
    @include colors-bg(unset, $color-gray-50, white);
    @include colors-text($color-gray-800, $color-gray-800, $color-gray-400);
    @include colors-border($color-gray-800, $color-gray-800, $color-gray-400, $color-gray-500);

    &.teal {
      @include colors-bg(white, $color-teal-50, white);
      @include colors-text($color-teal-600, $color-teal-600, $color-gray-400);
      @include colors-border($color-teal-600, $color-teal-600, $color-gray-400, $color-teal-300);
    }

    &.error {
      @include colors-bg(white, $color-red-50, white);
      @include colors-text($color-red-600, $color-red-600, $color-gray-400);
      @include colors-border($color-red-600, $color-red-600, $color-gray-400, $color-red-200);
    }
  }

  &.text {
    @include colors-bg(white, $color-gray-100, white);
    @include colors-text($color-gray-800, $color-gray-800, $color-gray-400);
    @include colors-border(none, none, none, $color-gray-300);

    &.teal {
      @include colors-bg(white, $color-teal-50, white);
      @include colors-text($color-teal-600, $color-teal-600, $color-gray-400);
      @include colors-border(none, none, none, $color-teal-300);
    }
  }

  // -------------------- SIZE --------------------
  &.lg {
    height: 48px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    @include margins(14px, 8px, 8px, 4px);
  }

  &.md {
    height: 40px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    @include margins(14px, 8px, 6px, 4px);
  }

  &.sm {
    height: 32px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    @include margins(14px, 8px, 6px, 4px);
  }
}
