@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1216px;
  margin: calc(100px + 64px) auto 100px;
  padding-inline: 24px;

  &.MOBILE {
    margin: calc(40px + 64px) auto 40px;
  }

  .main-info-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &.MOBILE {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    .info-text {
      display: flex;
      flex-direction: column;

      .title {
        @include font('h1');

        &.MOBILE {
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
        }
      }

      .description {
        font-size: 20px;
        line-height: 1.5;
        margin-top: 8px;
        color: $color-gray-800;

        &.MOBILE {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .search-bar {
      width: 280px;

      &.MOBILE {
        width: 100%;
      }
    }
  }

  .position-list {
    width: 100%;
    padding: 0;

    .position {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      list-style: none;
      padding: 24px 28px;
      margin-top: -1px;
      border-block: 1px solid $color-gray-400;
      cursor: pointer;
      width: 100%;
      overflow: hidden;

      &.MOBILE {
        flex-direction: column;
        padding-inline: 0;
      }

      &:hover {
        background-color: $color-gray-50;
      }

      .texts {
        display: flex;
        flex-direction: column;
        flex-basis: 1;
        gap: 8px;
        width: 100%;

        .title-area {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          width: 100%;
          overflow: hidden;

          .title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
          }
        }

        .info-area {
          display: flex;
          align-items: center;
          flex-shrink: 0;

          .tag {
            margin-right: 10px;
          }

          .submit-period {
            @include font('b3');
            color: $color-gray-600;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 8px;
        width: 100%;
        flex-basis: 0;

        button {
          width: 100%;
        }
      }
    }
  }
}
