@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/fonts';

.none-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.padding {
    margin: 124px auto;
  }

  h4 {
    @include font('subtitle', true);
    margin-top: 32px;
    color: $color-gray-800;
  }

  p {
    @include font('b2');
    margin-top: 8px;
    color: $color-gray-600;
  }
}
