@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  overflow: hidden;
  border: 1px solid transparent;

  .textarea {
    font-size: 14px;
    line-height: 22px;
    color: $color-gray-700;
    width: calc(100% + 4px);
    border: none;
    outline: none;
    background: transparent;

    &.darkMode {
      color: $color-gray-600;
    }
  }
}
