@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.paletteWrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
  gap: 12px;

  &.brand-color {
    margin-top: 8px;
  }

  .palette {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;

    &.white {
      border: 1px solid $color-gray-400;
    }
  }
}
