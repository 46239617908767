@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.container {
  display: flex;
  left: 0;
  transition: left 0.3s ease-in-out;

  > .option {
    display: flex;
    background: unset;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;

    &:disabled {
      cursor: not-allowed;
    }
  }

  // ----------------- Primary -------------------
  &.primary {
    gap: 8px;

    > .option {
      width: unset;
      height: 48px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      padding: 0 18px;
      border-radius: 30px;
      background: $color-gray-100;
      color: $color-gray-700;
      white-space: nowrap;

      &:not(:disabled) {
        &:hover {
          background: $color-gray-200;
        }

        &.active {
          background: $color-gray-800;
          color: $color-white;

          &:hover {
            background: $color-gray-900;
          }
        }
      }

      &:disabled {
        background: $color-gray-80;
        color: $color-gray-400;
      }
    }
  }

  // ----------------- Secondary -------------------
  &.secondary {
    background: $color-gray-80;
    padding: 3px 4px;
    border-radius: 6px;
    gap: 4px;
    min-height: 42px;

    > .option {
      height: 100%;
      padding-block: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $color-gray-600;
      border-radius: 6px;

      &:not(:disabled) {
        &:focus-visible {
          box-shadow: inset 0 0 0 3px $color-gray-400;
        }

        &:not(.active) {
          &:hover {
            background: $color-gray-200;
          }
        }
      }

      &.active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
        background: $color-white;
        color: $color-gray-800;
        font-weight: 700;
      }

      &:disabled {
        color: $color-gray-400;
      }
    }
  }

  // ----------------- Label -------------------
  &.label {
    > .option {
      padding: 12px 18px;
      color: $color-gray-600;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      position: relative;
      border-radius: 4px;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: $color-gray-400;
        bottom: 0;
        left: 0;
      }

      &:not(:disabled) {
        &:hover {
          color: $color-gray-800;

          &::after {
            background: $color-gray-800;
          }
        }

        &:focus-visible {
          box-shadow: inset 0 0 0 3px $color-gray-400;
        }
      }

      &:disabled {
        color: $color-gray-400;

        &::after {
          background: $color-gray-400;
        }
      }

      &.active {
        color: $color-gray-800;
        font-weight: 700;

        &::after {
          height: 2px;
          background: $color-gray-800;
        }
      }
    }
  }

  // ----------------- Inline Label -------------------
  &.inline-label {
    gap: 24px 36px;

    > .option {
      position: relative;
      width: fit-content;
      padding: 0 0 3px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: $color-gray-500;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: transparent;
        bottom: 0;
        left: 0;
      }

      &:not(:disabled) {
        &:hover {
          color: $color-gray-800;

          &::after {
            background: $color-gray-800;
          }
        }

        &:focus-visible {
          box-shadow: inset 0 0 0 3px $color-gray-400;
        }
      }

      &:disabled {
        color: $color-gray-400;

        &::after {
          background: $color-gray-400;
        }
      }

      &.active {
        color: $color-gray-800;

        &::after {
          background: $color-gray-800;
        }
      }
    }
  }

  // ----------------- Card -------------------
  &.card {
    > .option {
      border-radius: 6px 6px 0 0;
      position: relative;
      box-shadow: inset 0 0 0 1px $color-gray-300;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $color-gray-700;
      padding: 12px 18px;
      gap: 5px;

      > .right {
        height: 22px;
        background: $color-gray-80;
        color: $color-gray-500;
        border-radius: 11px;
        padding: 0 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
      }

      &::after {
        content: '';
        position: absolute;
        background: $color-teal-700;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
      }

      &:disabled {
        color: $color-gray-400;

        > .right {
          color: $color-gray-400;
        }

        &::after {
          background: $color-gray-400;
        }
      }

      &:not(:disabled):not(.active) {
        &:hover {
          background: $color-gray-100;
          box-shadow: none;
        }

        &:focus-visible {
          box-shadow: inset 0 0 0 3px $color-gray-400;
        }
      }

      &.active {
        background: $color-teal-600;
        color: $color-white;
        box-shadow: inset 0 0 0 2px $color-teal-700;

        > .right {
          background: $color-teal-700;
          color: $color-teal-200;
        }
      }
    }
  }
}

.swipe-arrow {
  position: absolute;
  z-index: 100;
  top: 1px;

  &.back {
    left: 0;
  }

  &.next {
    right: 0;
  }
}
