@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.pointer {
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 16px;
  transform: translate(-50%, -50%);
  background-color: $color-white;
  border-radius: 50%;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
}
