@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.listItem {
  display: flex;
  height: 100%;
  justify-content: flex-end;

  &.minWidth {
  min-width: 140px;
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: max-content;
    height: 42px;
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid $color-gray-800;
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #121619;
    cursor: pointer;

    > * {
      flex-shrink: 0;
    }

    &:hover {
      .depth-2 {
        display: block;
      }
    }

    &:not(.ignore-hover) {
      &:hover {
        background-color: $color-gray-80;
      }

      &.DARK:hover {
        background-color: $color-gray-700;
      }
    }

    .depth-2 {
      list-style: none;
      position: absolute;
      display: none;
      width: 138px;
      left: calc(50% - 69px);
      top: 41px;
      margin: 0;
      padding: 10px 6px;
      background: $color-white;
      border: 1px solid $color-gray-400;
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      z-index: 200;

      .subMenu {
        display: block;
        width: 100%;
        height: 32px;
        padding: 6px 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color-gray-800;

        &:hover {
          background: $color-gray-80;
          border-radius: 4px;
        }
      }
    }
  }
}