@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import "ViewPageTablet.module";
@import "ViewPageMobile.module";

.container {
  display: flex;
  flex-direction: column;
  padding: 64px 0 0 0;
  flex: 1 1;
}