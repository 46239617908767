@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import "ui/styles/base.scss";

.error-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  & > h1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include font('h2');
    gap: 0 10px;
    color: $color-gray-800;
    margin-top: 40px;

    & > span {
      white-space: nowrap;
    }
  }

  & > p {
    @include font('b2');
    color: $color-gray-800;
    margin-top: 16px;
    text-align: center;

  }
}

.expired {
  .goto-login-button {
    margin-top: 32px;
    width : 173px;
  }
}

.button {
  margin-top: 32px;
}