@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.popup-main {
  background-size: cover;
  background-position: center;

  &.--empty {
    background-color: $color-gray-50;
    width: 384px;
    height: 360px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}
