@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";

.hueArea {
  position: relative;
  height: 20px;
  border-radius: 100px;
  background: linear-gradient(
                  to right,
                  #f00 0%,
                  #ff0 17%,
                  #0f0 33%,
                  #0ff 50%,
                  #00f 67%,
                  #f0f 83%,
                  #f00 100%
  );
  cursor: pointer;

  .pointer {
    z-index: 2;
  }
}